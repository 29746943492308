import React from 'react';
// import { graphql, useStaticQuery, navigate } from 'gatsby';
import { useStaticQuery, navigate } from 'gatsby';

import Layout from 'molecules/Layout';
import { useKeyboardJs } from 'molecules/Keyboard';
import { InstaNodeProps } from 'molecules/InstagramGrid';
import HomePage from 'organisms/HomePage';

import 'styles/pages/home.scss';

// export const HOME_QUERY = graphql`
//   query HomeQuery {
//     allInstaNode(limit: 12, sort: { fields: timestamp, order: DESC }) {
//       edges {
//         node {
//           id
//           timestamp
//           localFile {
//             childImageSharp {
//               fluid(quality: 100, maxWidth: 600, maxHeight: 600) {
//                 ...GatsbyImageSharpFluid_withWebp
//               }
//             }
//           }
//           likes
//           comments
//           caption
//         }
//       }
//     }
//   }
// `;

interface Data {
  allInstaNode: {
    edges: {
      node: InstaNodeProps;
    }[];
  };
}

interface Props {}

const MyHomePage: React.FC<Props> = () => {
  useKeyboardJs('a > enter', () => navigate('/admin'));
  // const { allInstaNode } = useStaticQuery<Data>(HOME_QUERY);

  // const instaNodes = allInstaNode.edges.map(
  //   (e: { node: InstaNodeProps }) => e.node
  // );

  return (
    <Layout
      searchEngineOptimization={() => (
        <Layout.SEO
          path="/"
          canonical="/"
          lang="de-DE"
          description="Das THAMARAI Restaurant Heilbronn ist aktuell ein komplett kontkaktloser Liefer- und Abholservice für Spezialitäten der tamilisch indischen Küche in Heilbronn."
        />
      )}
      header={() => (
        <Layout.Header
          title="THAMARAI Restaurant Heilbronn"
          subtitle="Ganz einfach online bestellen und zwischen Lieferservice und Abholservice wählen"
          abstract="Das THAMARAI Restaurant Heilbronn ist aktuell ein komplett kontkaktloser Liefer- und Abholservice für Spezialitäten der tamilisch indischen Küche in Heilbronn. Wählen Sie mit wenigen Klicks zwischen Kothu Roti, Butter Chicken oder dem Panir Butter Masala. Geben Sie den Schärfegrad an und bezahlen Sie ganz bequem online. Geht aber auch bei Abholung. 30 Minuten später ist Ihre Bestellung zum Mitnehmen bereitgestellt. Unsere TakeAway Abholstation auf dem THAMARAI Parkplatz ermöglicht eine Übergabe ohne persönlichen Kontakt! Digital, zeitgemäß, einfach."
          alt="THAMARAI Restaurant Heilbronn Logo"
        />
      )}
    >
      {/* <HomePage instaNodes={instaNodes} /> */}
      <HomePage />
    </Layout>
  );
};

export default MyHomePage;
