import React from 'react';
import axios from 'axios';
import Button, { ButtonSize } from '@soosap/sushi/Button';
import Form from '@soosap/sushi/Form/Form';
import EmailField from '@soosap/sushi/Form/EmailField';
import TextField from '@soosap/sushi/Form/TextField';
import { useToasts, ToastTheme } from '@soosap/sushi/Toast';
import Description from 'atoms/Description';
import styles from './NewsletterSignup.module.scss';

const { GATSBY_BACKEND_URL } = process.env;
const isClient = typeof window !== 'undefined';

interface FormValues {
  name: string;
  email: string;
}

export interface Props {}

const NewsletterSignup: React.FC<Props> = () => {
  const { addToast } = useToasts();

  const onSubmit = async (values: FormValues) => {
    axios
      .post(`${GATSBY_BACKEND_URL}/api/newsletters/subscribe`, values)
      .then(() => {
        addToast(`Erfolgreich eingetragen`, {
          theme: ToastTheme.SUCCESS,
        });

        if (isClient) {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'call-to-action',
            category: 'Newsletter',
            action: 'Subscribe',
            label: 'Neuer Eintrag',
          });
        }
      })
      .catch((error) => {
        if (error) {
          addToast(`Bereits eingetragen`, {
            theme: ToastTheme.WARNING,
          });
        }
      });
  };

  return (
    <Description
      className={styles[`NewsletterSignup`]}
      title="Spezialwochen"
      subtitle="Newsletter"
    >
      <div className={styles[`NewsletterSignup__purpose`]}>
        Einige Klassiker der tamilischen Küche erfordern intensive Vorbereitung
        und können nicht à la Carte serviert werden. In regelmäßigen
        Spezialwochen verwöhnen wir Sie mit{' '}
        <span className={styles[`NewsletterSignup__special`]}>Biriyani</span>,{' '}
        <span className={styles[`NewsletterSignup__special`]}>Idiyappam</span>,{' '}
        <span className={styles[`NewsletterSignup__special`]}>Dosai</span> und
        anderen Kulinaritäten unserer Heimat. Wir informieren Sie gerne.
      </div>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, form, submitting, values }) => {
          return (
            <form
              className={styles[`NewsletterSignup__form`]}
              onSubmit={async (event) => {
                await handleSubmit(event);
                form.reset();

                (document.getElementById(
                  'newsletter-email'
                ) as HTMLInputElement).blur();

                form.blur('email');

                // This helps us to hide validation messages after submit
                Object.keys(values).forEach((key) => {
                  form.change(key, undefined);
                  form.resetFieldState(key);
                });
              }}
            >
              <TextField
                name="name"
                required
                requiredError="Pflichtfeld"
                placeholder="z.B. Ludovico Einaudi"
                label="Ihr Name"
              />
              <EmailField
                id="newsletter-email"
                name="email"
                required
                placeholder="z.B. ludovico@einaudi.de"
              />
              <Button
                type="submit"
                primary
                size={ButtonSize.TINY}
                disabled={submitting}
              >
                Eintragen
              </Button>
            </form>
          );
        }}
      />
    </Description>
  );
};

export default NewsletterSignup;
