import React from 'react';
import Order from 'organisms/Order';
// import InstagramGrid, { InstaNodeProps } from 'molecules/InstagramGrid';
import { InstaNodeProps } from 'molecules/InstagramGrid';

import NewsletterSignup from './components/NewsletterSignup';
import AboutThilagamBanner from '../AboutThilagamBanner';
import styles from './HomePage.module.scss';
import GrandOpening from 'organisms/GrandOpening';

interface Props {
  instaNodes?: InstaNodeProps[];
}

const HomePage: React.FC<Props> = () => {
  return (
    <div className={styles[`HomePage`]}>
      <GrandOpening />
      <Order />
      {/* <InstagramGrid nodes={instaNodes} /> */}
      <AboutThilagamBanner />
      <NewsletterSignup />
    </div>
  );
};

export default HomePage;
