import React from 'react';
import StackGrid from 'react-stack-grid';
import { useMedia } from 'react-use';
import Img, { GatsbyImageFluidProps } from 'gatsby-image';
import Icon from '@soosap/sushi/Icon';

import InstagramIcon from 'icons/Instagram';
import CommentIcon from 'icons/Comment';
import ThumbsUpIcon from 'icons/ThumbsUp';

function getHashtags(inputText: string) {
  var regex = /(?:^|\s)(?:#)([a-zA-Z\d]+)/gm;
  var matches = [];
  var match;

  while ((match = regex.exec(inputText))) {
    matches.push(match[1]);
  }

  return matches;
}

export interface InstaNodeProps {
  id: string;
  caption: string;
  timestamp: string;
  localFile: {
    childImageSharp: GatsbyImageFluidProps;
  };
  comments?: number;
  likes?: number;
}

const renderGridElements = (nodes: InstaNodeProps[]) => {
  return nodes
    .sort(
      (a, b) =>
        new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
    )
    .map((node) => {
      const hashtags = getHashtags(node.caption);
      return (
        <div key={node.id} className="InstagramGrid__item">
          <a
            aria-label="Clickable overlay that takes you to the instagram post"
            className="InstagramGrid__overlay"
            href={`https://www.instagram.com/p/${node.id}`}
          >
            &nbsp;
          </a>
          <Img
            fluid={node.localFile.childImageSharp.fluid}
            className="InstagramGrid__image"
          />
          <div className="InstagramGrid__details">
            <div className="InstagramGrid__hashtag">
              {`#${hashtags[0]}`}
              {hashtags && hashtags.length > 1 && (
                <div className="InstagramGrid__hashtag-two">{`#${hashtags[1]}`}</div>
              )}
            </div>
            <div className="InstagramGrid__icons">
              <div className="InstagramGrid__icon-wrapper">
                <div className="InstagramGrid__icon">
                  <div className="InstagramGrid__icon-count">
                    {node.comments && node.comments}
                  </div>
                  <Icon svg={CommentIcon} />
                </div>
              </div>
              <div className="InstagramGrid__icon-wrapper">
                <div className="InstagramGrid__icon">
                  <Icon svg={InstagramIcon} />
                </div>
              </div>
              <div className="InstagramGrid__icon-wrapper">
                <div className="InstagramGrid__icon-count">{node.likes}</div>
                <div className="InstagramGrid__icon">
                  <Icon svg={ThumbsUpIcon} />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });
};

interface Props {
  nodes: InstaNodeProps[];
}

const InstagramGrid: React.FC<Props> = ({ nodes }) => {
  const greaterThan380 = useMedia('(min-width: 380px)');
  const greaterThan420 = useMedia('(min-width: 420px)');
  const greaterThan480 = useMedia('(min-width: 480px)');
  const greaterThan640 = useMedia('(min-width: 640px)');
  const greaterThan768 = useMedia('(min-width: 768px)');
  const greaterThan992 = useMedia('(min-width: 992px)');
  const greaterThan1224 = useMedia('(min-width: 1224px)');

  const renderStackGrid = (): React.ReactElement => {
    if (greaterThan1224) {
      return (
        <StackGrid
          columnWidth={320}
          gutterWidth={7}
          gutterHeight={7}
          className="InstagramGrid__stack-grid"
        >
          {renderGridElements(nodes)}
        </StackGrid>
      );
    }

    if (greaterThan992) {
      return (
        <StackGrid
          columnWidth={290}
          gutterWidth={7}
          gutterHeight={7}
          className="InstagramGrid__stack-grid"
        >
          {renderGridElements(nodes)}
        </StackGrid>
      );
    }

    if (greaterThan768) {
      return (
        <StackGrid
          columnWidth={320}
          gutterWidth={7}
          gutterHeight={7}
          className="InstagramGrid__stack-grid"
        >
          {renderGridElements(nodes)}
        </StackGrid>
      );
    }

    if (greaterThan640) {
      return (
        <StackGrid
          columnWidth={280}
          gutterWidth={7}
          gutterHeight={7}
          className="InstagramGrid__stack-grid"
        >
          {renderGridElements(nodes)}
        </StackGrid>
      );
    }

    if (greaterThan480) {
      return (
        <StackGrid
          columnWidth={420}
          gutterWidth={7}
          gutterHeight={7}
          className="InstagramGrid__stack-grid"
        >
          {renderGridElements(nodes)}
        </StackGrid>
      );
    }

    if (greaterThan420) {
      return (
        <StackGrid
          columnWidth={380}
          gutterWidth={7}
          gutterHeight={7}
          className="InstagramGrid__stack-grid"
        >
          {renderGridElements(nodes)}
        </StackGrid>
      );
    }

    if (greaterThan380) {
      return (
        <StackGrid
          columnWidth={350}
          gutterWidth={7}
          gutterHeight={7}
          className="InstagramGrid__stack-grid"
        >
          {renderGridElements(nodes)}
        </StackGrid>
      );
    }

    return (
      <StackGrid
        columnWidth={320}
        gutterWidth={7}
        gutterHeight={7}
        className="InstagramGrid__stack-grid"
      >
        {renderGridElements(nodes)}
      </StackGrid>
    );
  };

  return (
    <div className="Home__InstagramGrid InstagramGrid">{renderStackGrid()}</div>
  );
};

export default InstagramGrid;
