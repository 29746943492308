import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img, { GatsbyImageFluidProps } from 'gatsby-image';
import Button, { ButtonSize } from '@soosap/sushi/Button';
import styles from './GrandOpening.module.scss';

const GRAND_OPENING_QUERY = graphql`
  query GrandOpeningQuery {
    welcomeBack: file(relativePath: { eq: "opening/willkommen-zurueck.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

interface Data {
  welcomeBack: {
    childImageSharp: GatsbyImageFluidProps;
  };
}

export interface Props {}

const GrandOpening: React.FC<Props> = () => {
  const { welcomeBack } = useStaticQuery<Data>(GRAND_OPENING_QUERY);
  return (
    <div className={styles['GrandOpening']}>
      <div className={styles['GrandOpening__text']}>
        <Img
          fluid={welcomeBack.childImageSharp.fluid}
          alt="Willkommen zurück!"
        />
      </div>
      <div className={styles['GrandOpening__cta']}>
        <Link to="/tisch-reservieren">
          <Button
            className={styles['GrandOpening__button']}
            size={ButtonSize.BIG}
          >
            Tisch reservieren
          </Button>
        </Link>
      </div>
      <div className={styles['GrandOpening__or']}>oder</div>
    </div>
  );
};

export default GrandOpening;
